export default
{
    BRAND_LOGO: require("../images/kyk.png"),
    BANNER_SECT: require("../images/banner-01.png"),
    GET_TOUCH: require("../images/bg-03.png"),
    ABOUT_SECT:  require("../images/about.png"),
    BUTTON: require("../images/button.png"),
    CHAKRA: require("../images/chakra.png"),
    PROFILE: require("../images/profile.png"),
    ACTIVATED: require("../images/activated.png"),
    INSTAGRAM: require("../images/instagram.png"),
    TWITTER: require("../images/twitter.png"),
    WHATSAPP: require("../images/whatsapp.png"),
    FACEBOOK: require("../images/facebook.png"),
    BUDHAN: require("../images/Budhan.png"),
    SURYA: require("../images/Surya.png"),
    CHANDRAN: require("../images/Chandra.png"),
    SHUKRA: require("../images/Shukra.png"),
    SHANI: require("../images/Shani.png"),
    SEVAI: require("../images/Sevai.png"),
    RAHU: require("../images/Raahu.png"),
    KETU: require("../images/ketu.png"),
    GURU: require("../images/Guru.png"),
    TOGGLE: require("../images/toggle.png"),
    // pdf
    JUPITER_PDF: require("../images/pdf/jupiter.pdf"),
    KETU_PDF: require("../images/pdf/ketu.pdf"),
    MERCURY_PDF: require("../images/pdf/mercury.pdf"),
    MOON_PDF: require("../images/pdf/moon.pdf"),
    RAAHU_PDF: require("../images/pdf/raahu.pdf"),
    SATURN_PDF: require("../images/pdf/saturn.pdf"),
    VENUS_PDF: require("../images/pdf/venus.pdf"),
    MARS_PDF: require("../images/pdf/mars.pdf"),   
    SUN_PDF: require("../images/pdf/sun.pdf"),  

    // pOSTER
    POSTER_1: require("../images/poster/1.webp"),
    POSTER_2: require("../images/poster/2.webp"),
    POSTER_3: require("../images/poster/3.webp"),
    // POSTER_4: require("../images/poster/4.webp"),
    // POSTER_5: require("../images/poster/5.webp"),
    // POSTER_6: require("../images/poster/6.webp"),
};
